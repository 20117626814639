input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 10px;
  box-sizing: border-box;
}

.main {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.auth-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-new-user, a {
  font-style: italic;
  color: antiquewhite;
  align-self: center;
}

.forget {
  margin-top: 2em;
  font-size: small;
  font-style: italic;
}

.input-task-container {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: right;
}

.btn-small-add-container {
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: left;
  margin-right: 10px;
}

.btn {
  border: none;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 16px;
  margin-left: 5px;
  transition-duration: 0.4s;
  font-weight: 600;
  border-radius: 3px;
}

.btn-login {
  background-color: #053e57;
  margin-top: 1.5em;
}

.btn-login:disabled {
  color: grey;
  transition: 1.5s;
}

.btn-logout {
  background-color: #5F9EA0;
}

.btn-add {
  background-color: #4CAF50;
  height: 40px;
  font-size: small;
}

.btn-del {
  background-color: #FF5733 ;
}

.btn-small {
  align-self: center;
  height: 35px;
  padding: 10px;
  font-size: small;
  margin-left: 0.5em;
}

.alert {
  padding: 10px;
  background-color: #053e57;
  margin-bottom: 15px;
  border-radius: 5px;
}

.task-container {
  display: flex;
  justify-content: space-between;
  margin: 0 10px
}

.nav-container {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
  margin: 0 10px
}

.btn-container {
  display: flex;
}

.App {
  display: flex;
  flex-direction: column;
  width: 95vw;
  height: 95vh;
  overflow: auto;
  margin: 0.5em;
}

fieldset {
  border: none;
  display: flex;
  flex-direction: column;
}

.App::-webkit-scrollbar {
  width: 0.5em;
}
 
.App::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.App::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}